import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Accordion from "../components/microrec/accordion/accordion";
import Kit from "../components/microrec/kit/kit";
import Areas from "../components/microrec/areas";
import logo from "/src/images/iso_color.png";
import Subscribe from "../components/misc/check_list";
import Tabs from "../components/main/tabs";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Grid } from "@mui/material";
import Compatibility from "../components/microrec/compatibility";
import Connect from "../components/microrec/bundle";
import Reviews from "../components/main/reviews";
import video from "../images/gifs/microrec.mp4";
import preLoader from "../images/gifs/microrec.png";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="Slit Lamp and Microscope Adapter - Med Imaging - MicroREC | Custom Surgical"
          description="Record, broadcast, or share your procedures up to 8K quality without any cables. MicroREC has German lenses to enhance the quality, and you can attach it in seconds."
        />{" "}
        <Subscribe />
        <>
          <Grid container xs={12} className="microrec_main">
            <video
              muted="true"
              autoplay="autoplay"
              loop="loop"
              id="microrec_main_video"
              poster={preLoader}
            >
              <source src={video} type="video/mp4" />
              <img
                src={preLoader}
                title="Your browser does not support the <video> tag"
              />
            </video>
            <h1 className="text_over_microrec_page">
              MICROREC
              <Grid item xs={10} sm={7}>
                <div
                  style={{
                    fontSize: "30px",
                    marginTop: "1em",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  The ultimate optical system to{" "}
                  <b>digitize your microscope or slit lamp</b>
                </div>
              </Grid>
              <button className="shop" style={{ marginTop: "3em" }}>
                <a href="/store" style={{ color: "white", fontSize: "24px" }}>
                  Shop now
                </a>
              </button>
            </h1>
          </Grid>
          <Kit />
          <Accordion />
          <Reviews />
          <Tabs />
          {/* <div style={{ marginTop: "3em" }}></div> */}
          {/* <App /> */}
          <Areas /> <Connect />
          <Compatibility />
        </>{" "}
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
