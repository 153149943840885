import { StaticImage } from "gatsby-plugin-image";

import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Container, Typography, Grid } from "@mui/material";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination, Autoplay, Mousewheel, Keyboard } from "swiper";

const Carousel = () => {
  return (
    <Box marginBottom={{ xs: 6, sm: 10 }} marginTop={{ xs: 6, sm: "5em" }}>
      <Container>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <Typography variant="h2" fontWeight={"bold"} paddingBottom={4}>
              Relevant for different specialties
            </Typography>
          </Grid>
        </Grid>
        <Swiper
          slidesPerView={1}
          breakpoints={{
            400: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          spaceBetween={0}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
          cssMode={true}
          navigation={true}
          pagination={false}
          mousewheel={false}
          modules={[Navigation, Pagination, Autoplay, Mousewheel, Keyboard]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Optometry.png" />
            </div>

            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Optometry
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Opthalmology.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Opthalmology
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Dentistry.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Dentistry
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/ENT.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                ENT
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Neurosurgery.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Neurosurgery
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Gynecology.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Gynecology
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <StaticImage src="../../images/areas/Laboratory.png" />
            </div>
            <div className="areas_block">
              <div
                style={{
                  color: "white",
                  background: "rgb(88 88 88 / 50%)",
                  padding: "10px 0px",
                }}
              >
                Laboratory
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Box>
  );
};

export default Carousel;
