import React, { useState } from "react";
import Stories from "react-insta-stories";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const play = {
  position: "absolute",
  zIndex: "2",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Video({ stories, currentIndex }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <button onClick={handleOpen} style={play} className="botón">
        <div class="fondo" x="0" y="0"></div>
        <div class="icono">
          <div class="parte izquierda" x="0" y="0" fill="#fff"></div>
          <div class="parte derecha" x="0" y="0" fill="#fff"></div>
        </div>
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 10,
              top: 10,
              color: "white",
              zIndex: "1000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stories
            currentIndex={currentIndex}
            stories={stories}
            defaultInterval={3000}
          />{" "}
        </Box>
      </Modal>
    </div>
  );
}
