import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import withc from "/src/images/with-CS.png";
import without from "/src/images/without.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        background: "#202026",
        paddingTop: "3em",
        paddingBottom: "3em",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "1200px" }}
      >
        <h1 className="tabs_h1">
          Work <b style={{ color: "#8F4FFF" }}>smarter</b>, not harder
        </h1>
        <div className="tabs_text">
          Now you can get the health information exchange you need, share
          content as efficiently as possible and show what you do the best.
          That's the medical technology it was missing in your medical routine.{" "}
        </div>
        <Box
          sx={{ width: "100%" }}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Box
            sx={{ borderBottom: 1, borderColor: "rgb(255 255 255 / 20%)" }}
            className="tabs_width"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  background: "#FFA100",
                },
              }}
              sx={{
                "& button": { color: "rgb(255 255 255 / 60%)" },
                "& button.Mui-selected": { color: "#FFA100" },
              }}
              aria-label="secondary tabs example"
              variant="fullWidth"
            >
              <Tab label="With Custom Surgical" {...a11yProps(0)} />
              <Tab label="Without Custom Surgical" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <img src={withc} className="tabs_img" alt="With Custom Surgical" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <img
              src={without}
              className="tabs_img"
              alt="Without Custom Surgical"
            />
          </TabPanel>
        </Box>
        <div
          style={{ textAlign: "center", color: "white", marginBottom: "1em" }}
        >
          Discover what Custom Surgical can do for you{" "}
        </div>
        <div style={{ textAlign: "center", color: "white" }}>
          <b>Get to know more about our products</b>
        </div>
        <button
          style={{
            left: "50%",
            marginTop: "3em",
            position: "relative",
            transform: "translate(-50%, 0px)",
          }}
          className="shop"
        >
          <a href="/store" style={{ color: "white" }}>
            SEE THE PRODUCTS
          </a>
        </button>
      </div>
    </div>
  );
}
